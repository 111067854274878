<template>
  <div>
    <page-title>Activity Reports</page-title>
    <page-subtitle>View all activity reports</page-subtitle>
    <div class="mt-5">
      <div class="bg-white p-3"></div>
    </div>
  </div>
</template>

<script>
import PageSubtitle from '../../components/Typography/PageSubtitle.vue'
import PageTitle from '../../components/Typography/PageTitle.vue'

export default {
  name: 'ActivityReport',
  components: { PageTitle, PageSubtitle }
}
</script>

<style></style>
