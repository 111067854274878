<template>
  <div>
    <t-modal v-model="showDetails">
      <div class="p-4">
        <h4 class="text-sm font-semibold text-gray-800">Update Appointment</h4>
        <div class="flex justify-end items-center">
          <div class="flex flex-col items-end space-y-1">
            <span class="text-xs font-semibold text-gray-600">10:45am</span>
            <span class="text-xs font-semibold text-gray-600">1/2/2012</span>
          </div>
        </div>
        <div>
          <p class="text-sm font-semibold capitalize text-primary">
            Odusote Mayokun
          </p>
          <p class="text-sm mt-4 font-normal text-gray-500">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            Architecto, recusandae error suscipit ut facere velit cupiditate rem
            eaque temporibus dolores optio harum sit? Autem, ex ea animi
            accusantium repellat, excepturi, eaque ratione assumenda earum quo
            omnis reprehenderit libero magni amet repellendus ut. Odio, dolore
            dolorum.
          </p>
        </div>
        <div class="flex mt-5 w-full justify-between items-center">
          <div class="w-9/12">
            <t-select
              class="w-9/12"
              placeholder="Update Progress..."
              :options="['Started', 'In progress', 'Done']"
            >
            </t-select>
          </div>
          <div>
            <t-button>Update</t-button>
          </div>
        </div>
      </div>
    </t-modal>
    <page-title>Payment Report</page-title>
    <page-subtitle>View all Payment report</page-subtitle>
    <div class="mt-5">
      <div class="flex rounded space-x-2 items-center justify-between mb-5">
        <div
          class="search-container border focus-within:ring-2 ring-primary border-gray-100 rounded pl-4 flex items-center w-full"
        >
          <img
            class="w-5 h-5"
            src="../../assets/svgs/search.svg"
            alt="search"
          />
          <input
            class="border-none shadow-none bg-transparent w-full focus:outline-none focus:border-none focus:ring-0"
            type="text"
            placeholder="Search by name, email..."
          />
        </div>
      </div>
      <div class="bg-white p-5 rounded">
        <div class="mb-5 bg-white">
          <!-- <div
            class="tabs flex flex-row space-x-3 text-sm font-semibold border-b border-gray-200"
          >
            <span
              class="py-3 px-5 cursor-pointer bg-primary text-white rounded-tl"
              >All</span
            >
            <span class="py-3 px-5 cursor-pointer">Fulfilled</span>
            <span class="py-3 px-5 cursor-pointer">Pending</span>
            <span class="py-3 px-5 cursor-pointer">Cancelled</span>
          </div> -->
          <div class="payment-list grid grid-cols-1 mt-5">
            <div class="flex flex-col w-full">
              <div class="overflow-auto">
                <table class="table-auto min-h-full min-w-full">
                  <thead
                    class="bg-primary-faded border-b-2 border-primary text-left font-semibold text-sm text-black"
                  >
                    <tr>
                      <th class="py-3 px-3">Name</th>
                      <th class="py-3 px-3">Total</th>
                      <th class="py-3 px-3">Amount Paid</th>
                      <th class="py-3 px-3">Remaining Balance</th>
                      <th class="py-3 px-3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="n in 10"
                      :key="n"
                      @click="showUpdateModal(n)"
                      class="text-sm cursor-pointer hover:border-l-2 hover:border-primary hover:bg-primary-faded"
                    >
                      <td class="px-3 py-4">Adegoke Oluwadamilare</td>
                      <td class="px-3 py-4">N 10,1000</td>
                      <td class="px-3 py-4">N 2000</td>
                      <td class="px-3 py-4">N 8000</td>
                      <td class="px-3 py-4">...</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubtitle from '../../components/Typography/PageSubtitle.vue'
import PageTitle from '../../components/Typography/PageTitle.vue'

export default {
  name: 'PaymentReport',
  components: { PageTitle, PageSubtitle },
  data: () => ({
    showDetails: false
  }),
  methods: {
    showUpdateModal(n) {
      this.showDetails = true
    }
  }
}
</script>

<style></style>
