<template>
  <div>
    <page-title>Tasks Reports</page-title>
    <page-subtitle>View all tasks reports</page-subtitle>
    <div class="mt-5">
      <div class="bg-white p-5 rounded">
        <div class="lists">
          <div
            v-for="(list, i) in lists"
            :key="i"
            :class="i % 2 === 0 ? 'bg-gray-100' : 'bg-white'"
            class="list md:flex text-left items-start justify-between w-full py-2"
          >
            <div class="md:ml-10 px-2">
              <p class="text-gray-800 text-base mb-1">
                {{ list.text }}
                <span class="text-primary">{{ list.user }}</span>
              </p>
              <p class="text-gray-500 text-base mb-1">{{ list.email }}</p>
              <p class="text-gray-500 text-base mb-1">{{ list.phone }}</p>
            </div>
            <div class="px-2">
              <p class="text-gray-500 text-base mb-1">
                Due on: {{ list.due_on }}
              </p>
              <p class="text-gray-500 text-base mb-1">
                Created on: {{ list.created_on }}
              </p>
            </div>
            <div class="md:mr-10 px-2">
              <p class="text-accent text-base font-bold">{{ list.due_date }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubtitle from '../../components/Typography/PageSubtitle.vue'
import PageTitle from '../../components/Typography/PageTitle.vue'

export default {
  name: 'TasksReport',
  components: { PageTitle, PageSubtitle },
  data: () => ({
    lists: [
      {
        text: 'Follow up with',
        user: 'Odusote Mayokun',
        email: 'example@platform.com',
        phone: '+234(0)8160730669',
        due_on: '1/15/2021',
        created_on: '1/12/2021',
        due_date: 'Due Today'
      },
      {
        text: 'Follow up with',
        user: 'Odusote Mayokun',
        email: 'example@platform.com',
        phone: '+234(0)8160730669',
        due_on: '1/15/2021',
        created_on: '1/12/2021',
        due_date: 'Due in 4 Today'
      },
      {
        text: 'Follow up with',
        user: 'Odusote Mayokun',
        email: 'example@platform.com',
        phone: '+234(0)8160730669',
        due_on: '1/15/2021',
        created_on: '1/12/2021',
        due_date: 'Due in 5 Today'
      }
    ]
  })
}
</script>

<style></style>
